// Core
import React from 'react';
// Library
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
// Components
import Header from './components/Header';
import Footer from './components/Footer';
// Pages
import Homepage from './pages/Homepage';
import PatentAnalytics from './pages/PatentAnalytics';
import PatentDrafting from './pages/PatentDrafting';
import PatentFiling from './pages/PatentFiling';
import PatentPro from './pages/PatentPro';
import PrivacyPolicy from './pages/PrivacyPolicy';
import RefreshPage from './components/RefreshPage';
import PageNotFound from './pages/Error404';
import About from './pages/About';
import ContactUs from './pages/ContactUs';
import Services from './pages/Services';
import TDomain from './pages/TDomain';

function App() {
    return (
        <div>
            <Router>
                <RefreshPage />
                <Header />
                <Switch>
                    <Route exact path='/' component={Homepage} />
                    <Route exact path='/about' component={About} />
                    <Route exact path='/services' component={Services} />
                    <Route exact path='/contact' component={ContactUs} />
                    <Route exact path='/domain' component={TDomain} />
                    <Route exact path='/services/patent-analytics' component={PatentAnalytics} />
                    <Route exact path='/services/patent-drafting' component={PatentDrafting} />
                    <Route exact path='/services/patent-filing' component={PatentFiling} />
                    <Route exact path='/services/patent-prosecution' component={PatentPro} />
                    <Route exact path='/privacy-policy' component={PrivacyPolicy} />
                    <Route component={PageNotFound} />   
                </Switch>
                <Footer />
            </Router>
        </div>
    );
}

export default App;
