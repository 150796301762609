export const menuItems = [
    {
        label: 'Home',
        path: '/',
        icon: '',
    },
    {
        label: 'Services',
        path: '/services',
        icon: '',
    },
    {
        label: 'Technology Domain',
        path: '/domain',
        icon: '',
    },
    {
        label: 'About',
        path: '/about',
        icon: '',
    },
    {
        label: 'Contact Us',
        path: '/contact',
        icon: '',
    }
]
