import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import 'aos/dist/aos.css';

const TestimonialsSlider = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    responsive: [
      {
        breakpoint: 1200,
        settings: { slidesToShow: 1 }
      }
    ]
  };

  const testimonials = [
    {
        id: 1,
        firm: 'US Patent Attorney',
        review: 'I am impressed with your exceptional work ethic, technical capability, and remarkable ability to grasp new concepts. The improvements I witnessed in this draft were truly outstanding. The application has now reached a world-class standard, comparable to any top law firm and even surpassing the quality of renowned patent law firms.',
    },
    {
        id: 2,
        name: 'Co-Founder',
        firm: 'Start-up in Rehabilitation Devices',
        review: " 'Saras IP's expertise in conducting thorough prior art searches and skillful patent drafting significantly contributed to Rymo's success. Their commitment to timely delivery and unwavering focus on quality exemplify a commendable dedication to their clients. We highly appreciate their pivotal role in our patent processes. ",
    },
    {
      id: 3,
      name: 'Co-Founder',
      firm: 'Start-up in Assistive Devices',
      review: "Our company has designed and manufactured a unique product for individuals with special needs and we needed a Patent Service provider Firm who understands the brief for the Patent requirement and work with us seamlessly. SARAS IP with their patience, sensitivity towards client brief, depth of knowledge in the given subject and a very keen sense towards fine detailing of the product in their documentation have not only safeguarded the product but also addressed the company’s values in a very meticulous and effective manner. SARAS IP's constant support and guidance reinforces the trust they hold to safeguard our company and move forward unhindered. ",
  }
  ];

  return (
    <section id="testimonials" data-aos="fade-up">
      <div className="container text-center testimonial-container">
        <h6 className="sub-heading">Testimonials</h6>
        <h2>What Our <span className='heading-animate'>Clients Say</span></h2>
        <Slider {...settings}>
          {testimonials.map((testimonial) => (
            <div key={testimonial.id} className='testimonial-item'>
              <p>{testimonial.review}</p>
              <h4>{testimonial.name}</h4>
              <span>{testimonial.firm}</span>
            </div>
          ))}
        </Slider>
      </div>
    </section>
  );
};

export default TestimonialsSlider;
