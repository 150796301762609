import React from 'react';
import ArtificialIntelligence from '../assets/images/icons/1.svg';
import AutomonousVehicle from '../assets/images/icons/2.svg';
import RenewableEnery from '../assets/images/icons/3.svg';
import Electrical from '../assets/images/icons/4.svg';
import Ecommerce from '../assets/images/icons/5.svg';
import TeleCommunications from '../assets/images/icons/6.svg';
import Electronics from '../assets/images/icons/7.svg';
import SoftwareApps from '../assets/images/icons/8.svg';
import SemiConductors from '../assets/images/icons/9.svg';
import MedicalDevice from '../assets/images/icons/10.svg';
import ConsumerProduct from '../assets/images/icons/11.svg';
import IoT from '../assets/images/icons/12.svg';
import ComputerNetworks from '../assets/images/icons/13.svg';
import WirelessTechnology from '../assets/images/icons/14.svg';
import Battery from '../assets/images/icons/15.svg';
import Robotics from '../assets/images/icons/16.svg';
import Medical from '../assets/images/icons/17.svg';
import Satellite from '../assets/images/icons/18.svg';
import MetaTags from 'react-meta-tags';
import ScrollToTop from '../components/ScrollToTop';
import 'aos/dist/aos.css';
import AOS from 'aos';
AOS.init();

const TDomain = () => {

    const slides = [
        {
          id: 1,
          logo: AutomonousVehicle,
          title: 'Autonomous Vehicles',
        },
        {
            id: 2,
            logo: ArtificialIntelligence,
            title: 'Artificial Intelligence',
        },
        {
            id: 3,
            logo: RenewableEnery,
            title: 'Renewable Energy & Power',
          },
          {
            id: 4,
            logo: MedicalDevice,
            title: 'Medical Devices & Healthcare',
          },
          {
            id: 5,
            logo: ConsumerProduct,
            title: 'Consumer Products',
          },
          {
            id: 6,
            logo: Electronics,
            title: 'Electronics & Mechatronics',
          },
          {
            id: 7,
            logo: SoftwareApps,
            title: 'Software-Apps & Business Methods',
          },
          {
            id: 8,
            logo: SemiConductors,
            title: 'Semiconductor Products',
          },
          {
            id: 9,
            logo: TeleCommunications,
            title: 'Telecommunication',
          },
          {
            id: 10,
            logo: Ecommerce,
            title: 'E-commerce & Software',
          },
          {
            id: 11,
            logo: Electrical,
            title: 'Electrical & Electronics',
          },
          {
            id: 12,
            logo: Battery,
            title: 'Battery & Power Generation',
          },
          {
            id: 13,
            logo: Robotics,
            title: 'Robotics & Automation',
          },
          {
            id: 14,
            logo: WirelessTechnology,
            title: 'Wireless Technology',
          },
          {
            id: 15,
            logo: ComputerNetworks,
            title: 'Computer Networks',
          },
          {
            id: 16,
            logo: IoT,
            title: 'IoT & Wearables',
          },
          {
            id: 17,
            logo: Medical,
            title: 'Medical & Assistive Devices',
          },
          {
            id: 18,
            logo: Satellite,
            title: 'Satellite Technology',
          }
      ];

  return (
    <>
    <ScrollToTop />
    <MetaTags>
        <title>Saras IP | Technology Domains in Patent Services</title>
        <meta name="description" content="Saras IP specializes in patent services across AI, Autonomous Vehicles, Renewable Energy, Medical Devices, IoT, and more. Protect your innovations with our experts." />
        <meta name="keywords" content="patent services, technology domains, AI patents, autonomous vehicle patents, renewable energy patents, medical device patents, IoT patents, semiconductor patents, telecommunications patents" />
    </MetaTags>
    <section className="breadcrumbs">
            <div className="inner">
                <div className="container">
                    <h1>Technology Domain</h1>
                    <p>Technology We Work On</p>
                </div>
            </div>
    </section>
    <section id="domain" data-aos="fade-up">
      <div className="container text-center domain-container">
        <div className="row row-cols-lg-3 row-cols-md-3 row-cols-sm-2 row-cols-2 g-4">
          {slides.map((slide) => (
            <div key={slide.id} className="col d-flex flex-column align-items-center">
              <img src={slide.logo} alt={slide.title} className="mt-4 custom-icon" />
              <p className="custom-text mt-3 text-center">{slide.title}</p>
            </div>
          ))}
        </div>
      </div>
    </section>
    </>
  );
};

export default TDomain;