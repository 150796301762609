import React, { useState, useEffect } from 'react';
import MetaTags from 'react-meta-tags';
import { Link } from 'react-router-dom';
import ScrollToTop from '../components/ScrollToTop';
import ContactForm from '../components/Contact';
import TestimonialsSlider from '../components/Testimonials';
import FormDialog from '../components/GetQuote';
import Domain from '../components/Domain';
import AboutUs from '../components/AboutUs';
import Analytics from '../assets/images/analytics.svg';
import Drafting from '../assets/images/drafting.svg';
import Prosecution from '../assets/images/prosecution.svg';
import Filing from '../assets/images/filing.svg';
import 'aos/dist/aos.css';
import AOS from 'aos';
AOS.init();

const Homepage = () => {
  const [activeIndex, setActiveIndex] = useState(0);

  const toggleAccordion = (index) => {
    if (activeIndex === index) {
      setActiveIndex(null);
    } else {
      setActiveIndex(index);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <ScrollToTop />
      <MetaTags>
        <title>Saras IP - Expert Patent Services Provider & IP Protection | Best IP Protection Company in India</title>
        <meta name="description" content="Saras IP offers expert patent services, including patent analytics, drafting, filing, prosecution, and intellectual property protection, to safeguard your innovations." />
      </MetaTags>
      <section id="hero" data-aos="fade-up">
        <div className='hero-text'>
          <div className="container position-relative">
            <div className="row">
              <div className="col-12 col-lg-10 mb-lg-4">
                <h1 className="text-light mb-3">
                  Welcome to Saras IP
                </h1>
                <h2 className="display-2 text-light mb-3">
                  Turning Ideas into Assets, Patents Perfected Here.
                </h2>
                <ol className="breadcrumb mb-4 pb-2">
                  <li className="breadcrumb-item text-light">
                    Invent
                  </li>
                  <li className="breadcrumb-item text-light">
                    Protect
                  </li>
                  <li className="breadcrumb-item text-light">
                    Thrive
                  </li>
                </ol>
                <a href="/#services" className="btn"> Get Started</a>
                <FormDialog />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="services" data-aos="fade-up">
        <div className="container text-center">
          <div className="row">
            <div className="col-12">
              <div className="intro">
                <h6 className='sub-heading'>Our Services</h6>
                <h2>What <span className='heading-animate'>We Do?
                </span></h2>
                <p className="mx-auto">Explore our comprehensive Patent Services, covering Analytics, Drafting, Prosecution, and Filing.</p>
              </div>
            </div>
          </div>
          <div className="row g-4 my-2">
            <div className="col-lg-3 col-md-6">
                <div className="shadow-lg service h-100">
                <img src={Analytics} alt="" />
                <h5>Patent Analytics</h5>
                <p>Our Patent Analytics service empowers clients with comprehensive insights into industry trends, competitor activities, and technology landscapes, aiding informed decision-making.</p>
                <Link to="/services/patent-analytics" className="btn">View more</Link>
                </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="shadow-lg service h-100">
                <img src={Drafting} alt="" />
                <h5>Patent Drafting</h5>
                <p>We specialize in providing meticulous Patent Drafting services, skillfully translating innovative concepts into precise and legally robust patent applications to safeguard your intellectual property.</p>
                <Link to="/services/patent-drafting" className="btn">View more</Link>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="shadow-lg service h-100">
                <img src={Filing} alt="" />
                <h5>Patent Filing</h5>
                <p>With our specialized Patent Filing service, clients can confidently initiate the comprehensive legal protection process, ensuring timely and accurate submission of patent applications to relevant authorities.</p>
                <Link to="/services/patent-filing" className="btn">View more</Link>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="shadow-lg service h-100">
                <img src={Prosecution} alt="" />
                <h5>Patent Prosecution</h5>
                <p> Navigating the complexities of Patent Prosecution, our experts work closely with inventors and patent offices to secure intellectual property rights throughout the examination process.</p>
                <Link to="/services/patent-prosecution" className="btn">View more</Link>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Domain/>
      <section id='FAQ' data-aos="fade-up">
        <div className='container'>
        <h6 className='sub-heading'>Got Questions?</h6>
            <h2 className='mb-5'>Frequently <span className='heading-animate'>Asked Questions</span></h2>
            <div className="accordion" id="accordionExample">
            <div className="accordion-item">
                <h2 className="accordion-header" id="headingOne">
                <button
                    className={`accordion-button ${activeIndex === 0 ? 'active' : ''}`}
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseOne"
                    aria-expanded={activeIndex === 0}
                    aria-controls="collapseOne"
                    onClick={() => toggleAccordion(0)}
                >
                    1. What is the purpose of a patent?
                </button>
                </h2>
                <div
                id="collapseOne"
                className={`accordion-collapse collapse ${activeIndex === 0 ? 'show' : ''}`}
                aria-labelledby="headingOne"
                data-bs-parent="#accordionExample"
                >
                <div className="accordion-body">
                    A patent grants inventors exclusive rights to their innovations, preventing others from making, using, or selling the invention for a specific period. It's a legal tool to protect intellectual property.
                </div>
                </div>
            </div>
            <div className="accordion-item">
                <h2 className="accordion-header" id="headingTwo">
                <button
                    className={`accordion-button ${activeIndex === 1 ? 'active' : ''}`}
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseTwo"
                    aria-expanded={activeIndex === 1}
                    aria-controls="collapseTwo"
                    onClick={() => toggleAccordion(1)}
                >
                    2. How do I know if my invention is patentable?
                </button>
                </h2>
                <div
                id="collapseTwo"
                className={`accordion-collapse collapse ${activeIndex === 1 ? 'show' : ''}`}
                aria-labelledby="headingTwo"
                data-bs-parent="#accordionExample"
                >
                <div className="accordion-body">
                    To determine patent eligibility, your invention must be novel, non-obvious, and useful. Consult with a patent attorney or use our Patent Analytics service for a thorough assessment.
                </div>
                </div>
            </div>
            <div className="accordion-item">
                <h2 className="accordion-header" id="headingThree">
                <button
                    className={`accordion-button ${activeIndex === 2 ? 'active' : ''}`}
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseThree"
                    aria-expanded={activeIndex === 2}
                    aria-controls="collapseThree"
                    onClick={() => toggleAccordion(2)}
                >
                    3. What is the difference between a utility patent and a design patent?
                </button>
                </h2>
                <div
                id="collapseThree"
                className={`accordion-collapse collapse ${activeIndex === 2 ? 'show' : ''}`}
                aria-labelledby="headingThree"
                data-bs-parent="#accordionExample"
                >
                <div className="accordion-body">
                    A utility patent covers the functionality or method of an invention, while a design patent protects the ornamental design or appearance.
                </div>
                </div>
            </div>
            <div className="accordion-item">
                <h2 className="accordion-header" id="headingFour">
                <button
                    className={`accordion-button ${activeIndex === 3 ? 'active' : ''}`}
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseFour"
                    aria-expanded={activeIndex === 3}
                    aria-controls="collapseFour"
                    onClick={() => toggleAccordion(3)}
                >
                    4. How long does the patent process take?
                </button>
                </h2>
                <div
                id="collapseFour"
                className={`accordion-collapse collapse ${activeIndex === 3 ? 'show' : ''}`}
                aria-labelledby="headingFour"
                data-bs-parent="#accordionExample"
                >
                <div className="accordion-body">
                    The patent process varies but typically takes 2-3 years. Factors include the complexity of the invention and the patent office's backlog.
                </div>
                </div>
            </div>
            <div className="accordion-item">
                <h2 className="accordion-header" id="headingFive">
                <button
                    className={`accordion-button ${activeIndex === 4 ? 'active' : ''}`}
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseFive"
                    aria-expanded={activeIndex === 4}
                    aria-controls="collapseFive"
                    onClick={() => toggleAccordion(4)}
                >
                    5. Can I conduct a patent search on my own?
                </button>
                </h2>
                <div
                id="collapseFive"
                className={`accordion-collapse collapse ${activeIndex === 4 ? 'show' : ''}`}
                aria-labelledby="headingFive"
                data-bs-parent="#accordionExample"
                >
                <div className="accordion-body">
                    Yes, but it's highly recommended to use professional Patent Analytics services for comprehensive and accurate patent searches.
                </div>
                </div>
            </div>
            <div className="accordion-item">
                <h2 className="accordion-header" id="headingSix">
                <button
                    className={`accordion-button ${activeIndex === 5 ? 'active' : ''}`}
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseSix"
                    aria-expanded={activeIndex === 5}
                    aria-controls="collapseSix"
                    onClick={() => toggleAccordion(5)}
                >
                    6. How can I get in touch with your company for patent-related services?
                </button>
                </h2>
                <div
                id="collapseSix"
                className={`accordion-collapse collapse ${activeIndex === 5 ? 'show' : ''}`}
                aria-labelledby="headingSix"
                data-bs-parent="#accordionExample"
                >
                <div className="accordion-body">
                    You can contact us by mail at <a href="mailto:info@sarasip.com">info@sarasip.com</a>
                </div>
                </div>
            </div>
            </div>
        </div>
    </section> 
      <AboutUs/>
      <TestimonialsSlider/>
      <ContactForm/>
    </div>
  );
};

export default Homepage;