import React, { useEffect } from 'react';
import 'aos/dist/aos.css';
import AOS from 'aos';
import Sagar from '../assets/images/SagarShethia.png'

const AboutUs = () => {
  useEffect(() => {
    AOS.init({
      duration: 1000,
      once: true,
    });
  }, []);

  return (
    <section id="about" className="py-5">
      <div className="container about-container">
        <div className="row justify-content-center">
          <div className="col-12 text-center mb-4 mb-md-5">
            <h6 className="sub-heading">About Us</h6>
            <h2>Who <span className='heading-animate'>We Are</span></h2>
          </div>
        </div>

        <div className="row align-items-center">
          <div 
            className="col-lg-6 order-2 order-lg-1 mt-4 mt-lg-0"
            data-aos="fade-right"
            data-aos-delay="200"
          >
            <div className="about-content text-center text-lg-start pe-lg-4">
              <p className="mb-3 mb-md-3">
                Sagar Shethia is the founder of SARAS IP. With a strong background in electronics and telecommunication engineering, coupled with a LLB degree, Sagar bring a unique blend of technical expertise and legal acumen to the field of intellectual property.
              </p>
              <p className="mb-3 mb-md-3">
                6+ years of experinece in IP. Sucessfully established SARAS IP to offer comprehensive patent analytics, drafting, prosecution, and filing services.
              </p>
              <p className="mb-3 mb-md-3">
                Instrumental/Expertise in delivering tailored IP solutions that meet the specific needs of inventors, startups, educational institutes, and established businesses.
              </p>
              <p className="mb-3 mb-md-3">
                SARAS IP is making a significant impact in the world of patent services, supporting the growth and protection of innovative ideas.
              </p>
              <p className="mb-3 mb-md-3">
                LLB from Father Agnel Technical Education Complex. B.Tech (EXTC) from KJ Somaiya College of Engineering, Vidyavihar. Indian Patent Agent (IN/PA 3591).
              </p>
            </div>
          </div>

          <div 
            className="col-lg-6 order-1 order-lg-2"
            data-aos="fade-left"
            data-aos-delay="300"
          >
            <div className="ceo-photo text-center position-relative">
              <div className="image-container">
                <div className="border-frame"></div>
                <img 
                  src={Sagar}
                  alt="Sagar Shethia, Founder" 
                  className="img-fluid main-image"
                  loading="lazy"
                />
              </div>
              <div className="ceo-info mt-3 mt-md-4">
                <h4 className="mb-0 fs-5 fs-md-4">Sagar Shethia</h4>
                <p className="text-muted mb-0 fs-6 fs-md-5">Founder</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <style jsx>{`
        .image-container {
          position: relative;
          max-width: 280px;
          margin: 0 auto;
          border-radius: 15px;
          transition: transform 0.3s ease;
        }

        .border-frame {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          border: 2px solid #000;
          border-radius: 15px;
          z-index: 1;
          box-shadow: 0 20px 40px -15px rgba(0, 0, 0, 0.9); // Stronger shadow
        }

        .main-image {
          position: relative;
          z-index: 2;
          width: 100%;
          height: auto;
          border-radius: 10px;
          transition: all 0.3s ease;
          
        }

        .image-container:hover {
            transform: scale(1.02);
          }

          .image-container:hover .main-image {
            transform: scale(1.1);
          }

          .image-container:hover .border-frame {
            box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.4);
          }

        .about-content p {
            font-style: italic;
        }

        @media (max-width: 768px) {
          .image-container {
            max-width: 280px;
            padding: 8px;
          }
          
          .border-frame {
            border-width: 2px;
          }
        }

       @media (min-width: 992px) {
          .image-container {
            max-width: 360px;
            padding: 15px;
          }
          
          .border-frame {
            border-width: 2px;
          }
        }

        .heading-animate {
          position: relative;
          display: inline-block;
          color: #2563eb;
        }

        .sub-heading {
          color: #6b7280;
          font-size: 1.1rem;
          letter-spacing: 0.1em;
          text-transform: uppercase;
          margin-bottom: 0.8rem;
        }
      `}</style>
    </section>
  );
};

export default AboutUs;