import React, { useEffect } from 'react';
import 'aos/dist/aos.css';
import AOS from 'aos';
import MetaTags from 'react-meta-tags';
import { Link } from 'react-router-dom';
import ScrollToTop from '../components/ScrollToTop';
import Analytics from '../assets/images/analytics.svg';
import Drafting from '../assets/images/drafting.svg';
import Prosecution from '../assets/images/prosecution.svg';
import Filing from '../assets/images/filing.svg';

const Services = () => {
  useEffect(() => {
    AOS.init({
      duration: 1000,
      once: true,
    });
  }, []);

  return (
    <>
    <ScrollToTop />
    <MetaTags>
      <title>Saras IP | Expert Patent Services - Analytics, Drafting, Filing & Prosecution</title>
      <meta name="description" content="Saras IP provides comprehensive patent services including expert patent analytics, precise drafting, global filing, and thorough prosecution. Protect your intellectual property with our tailored solutions." />
      <meta name="keywords" content="patent services, patent analytics, patent drafting, patent filing, patent prosecution, intellectual property protection, IP management, patent attorneys, patent strategy" />
    </MetaTags>
    <section className="breadcrumbs">
            <div className="inner">
                <div className="container">
                    <h1>Our Services</h1>
                    <p>Who We Do?</p>
                </div>
            </div>
    </section>
    <section id="services" data-aos="fade-up">
        <div className="container text-center">
          <div className="row g-4 my-2">
            <div className="col-lg-3 col-md-6">
                <div className="shadow-lg service h-100">
                <img src={Analytics} alt="" />
                <h5>Patent Analytics</h5>
                <p>Our Patent Analytics service empowers clients with comprehensive insights into industry trends, competitor activities, and technology landscapes, aiding informed decision-making.</p>
                <Link to="/services/patent-analytics" className="btn">View more</Link>
                </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="shadow-lg service h-100">
                <img src={Drafting} alt="" />
                <h5>Patent Drafting</h5>
                <p>We specialize in providing meticulous Patent Drafting services, skillfully translating innovative concepts into precise and legally robust patent applications to safeguard your intellectual property.</p>
                <Link to="/services/patent-drafting" className="btn">View more</Link>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="shadow-lg service h-100">
                <img src={Filing} alt="" />
                <h5>Patent Filing</h5>
                <p>With our specialized Patent Filing service, clients can confidently initiate the comprehensive legal protection process, ensuring timely and accurate submission of patent applications to relevant authorities.</p>
                <Link to="/services/patent-filing" className="btn">View more</Link>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="shadow-lg service h-100">
                <img src={Prosecution} alt="" />
                <h5>Patent Prosecution</h5>
                <p> Navigating the complexities of Patent Prosecution, our experts work closely with inventors and patent offices to secure intellectual property rights throughout the examination process.</p>
                <Link to="/services/patent-prosecution" className="btn">View more</Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Services;